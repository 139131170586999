class ScrollTo {
  constructor(el) {
    this._scroll(this, el);
  }

  _getTargetPosition(el) {
    const target = $(el).data().scrollTo;
    const targetPos = $(target).offset().top;

    return targetPos;
  }

  _scroll(_this, el) {
    $(el).on('click', function() {
      let offset = 50;
      
      if ($(document).width() > 768) {
        offset = 0;
      }

      $('html, body').animate({
        scrollTop: _this._getTargetPosition(el) - offset
      }, '500');

      return false;
    });
  }
}

export default ScrollTo;
